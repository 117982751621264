import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/working_dir/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import Component from 'react-component-component';
import { Favorites } from '../../src';
import { FavoritesProvider, FavoritesContext, useFavoritesContext } from '../../src';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "favorites"
    }}>{`Favorites`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import Favorites from '@mfrm/mfcl/Favorites'
import { FavoritesProvider, FavoritesContext, useFavoritesContext } from '@mfcl/mfcl/FavoritesProvider'
`}</code></pre>
    <h2 {...{
      "id": "description"
    }}>{`Description`}</h2>
    <p>{`Favorites component handles displaying mattresses and adjustable bases favorited
by the user. The Favorites component also manages creating and updating the
user's favorites, along with synchronizing session state between the API.`}</p>
    <p>{`Favorites component may be used independently of the Favorites component.`}</p>
    <h2 {...{
      "id": "favoritesprovider-properties"
    }}>{`FavoritesProvider Properties`}</h2>
    <Props of={FavoritesProvider} mdxType="Props" />
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic Usage`}</h2>
    <Playground __position={1} __code={'<FavoritesProvider\n  enableFavorites={true}\n  toggleMockData={true}\n></FavoritesProvider>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Component,
      Favorites,
      FavoritesProvider,
      FavoritesContext,
      useFavoritesContext,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <FavoritesProvider enableFavorites={true} toggleMockData={true} mdxType="FavoritesProvider"></FavoritesProvider>
    </Playground>
    <h2 {...{
      "id": "usage-with-optional-props"
    }}>{`Usage with optional props`}</h2>
    <Playground __position={2} __code={'<FavoritesProvider\n  toggleMockData={false}\n  getFavorites={() => undefined}\n  onAdd={() => undefined}\n  onDelete={() => undefined}\n  onClearAll={() => undefined}\n></FavoritesProvider>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Component,
      Favorites,
      FavoritesProvider,
      FavoritesContext,
      useFavoritesContext,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <FavoritesProvider toggleMockData={false} getFavorites={() => undefined} onAdd={() => undefined} onDelete={() => undefined} onClearAll={() => undefined} mdxType="FavoritesProvider"></FavoritesProvider>
    </Playground>
    <h2 {...{
      "id": "usage-with-context-hook"
    }}>{`Usage with Context hook`}</h2>
    <Playground __position={3} __code={'<FavoritesProvider>\n  {() => {\n    const { favoritesCount } = useFavoritesContext()\n    return <div>{favoritesCount}</div>\n  }}\n</FavoritesProvider>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Component,
      Favorites,
      FavoritesProvider,
      FavoritesContext,
      useFavoritesContext,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <FavoritesProvider mdxType="FavoritesProvider">
    {() => {
          const {
            favoritesCount
          } = useFavoritesContext();
          return <div>{favoritesCount}</div>;
        }}
  </FavoritesProvider>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      